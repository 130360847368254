.ant-slide-up-enter,
.ant-slide-up-appear {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-slide-up-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
	opacity: 0;
	-webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
@-webkit-keyframes antSlideUpIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@keyframes antSlideUpIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@-webkit-keyframes antSlideUpOut {
	0% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}
@keyframes antSlideUpOut {
	0% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}
@-webkit-keyframes antSlideDownIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.ant-select-single .ant-select-selector {
	cursor: pointer;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
	position: absolute;
	top: 0;
	right: 11px;
	bottom: 0;
	left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
	width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	padding: 0;
	line-height: 30px;
	transition: all 0.3s;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
	display: none;
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #40a9ff;
	border-right-width: 1px !important;
}
.ant-select-selection-item {
	flex: 1;
	overflow: hidden;
	font-weight: normal;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-select-arrow {
	display: inline-block;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	top: 50%;
	right: 11px;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 1;
	text-align: center;
	pointer-events: none;
}
.ant-select-dropdown {
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1;
	box-sizing: border-box;
	overflow: hidden;
	font-size: 14px;
	font-variant: initial;
	background-color: #fff;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown-hidden {
	display: none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-empty {
	margin: 0 8px;
	font-size: 14px;
	line-height: 1.5715;
	text-align: center;
}
.ant-empty-image {
	height: 100px;
	margin-bottom: 8px;
}
.ant-empty-image img {
	height: 100%;
}
.ant-empty-image svg {
	height: 100%;
	margin: auto;
}
.ant-empty-footer {
	margin-top: 16px;
}
.ant-empty-normal {
	margin: 32px 0;
	color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
	height: 40px;
}
.ant-empty-small {
	margin: 8px 0;
	color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
	height: 35px;
}
.ant-empty-img-default-ellipse {
	fill: #f5f5f5;
	fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
	fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
	fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
	fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
	fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
	fill: #dce0e6;
}
.ant-empty-img-default-g {
	fill: #fff;
}
.ant-empty-img-simple-ellipse {
	fill: #f5f5f5;
}
.ant-empty-img-simple-g {
	stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
	fill: #fafafa;
}
.ant-empty-rtl {
	direction: rtl;
}
