@font-face {
	font-family: geoRegular;
	src: url('/fonts/GeoramaExpanded-Regular.woff2') format('woff2'), url('/fonts/GeoramaExpanded-Regular.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: unset;
}

@font-face {
	font-family: geoSemiBold;
	src: url('/fonts/GeoramaExpanded-SemiBold.woff2') format('woff2'), url('/fonts/GeoramaExpanded-SemiBold.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: unset;
}

@font-face {
	font-family: geoLight;
	src: url('/fonts/Georama-Light.woff2') format('woff2'), url('/fonts/Georama-Light.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: unset;
}

@font-face {
	font-family: grandItalic;
	src: url('/fonts/GrandSlang-Italic.woff2') format('woff2'), url('/fonts/GrandSlang-Italic.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: unset;
}

@font-face {
	font-family: grandRoman;
	src: url('/fonts/GrandSlang-Roman.woff2') format('woff2'), url('/fonts/GrandSlang-Roman.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: unset;
}

@font-face {
	font-family: michroma;
	src: url('/fonts/Michroma-Regular.woff2') format('woff2'), url('/fonts/Michroma-Regular.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: unset;
}
