@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?gfk2ex');
  src:  url('fonts/icomoon.eot?gfk2ex#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?gfk2ex') format('woff2'),
    url('fonts/icomoon.ttf?gfk2ex') format('truetype'),
    url('fonts/icomoon.woff?gfk2ex') format('woff'),
    url('fonts/icomoon.svg?gfk2ex#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="bw-"], [class*=" bw-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bw-burger:before {
  content: "\e902";
}
.bw-plus:before {
  content: "\e909";
}
.bw-minus:before {
  content: "\e90d";
}
.bw-facebook:before {
  content: "\e908";
}
.bw-success:before {
  content: "\e915";
  color: #efefef;
}
.bw-arrow:before {
  content: "\e914";
}
.bw-Cart_errow_button-04:before {
  content: "\e90e";
}
.bw-Basket:before {
  content: "\e901";
}
.bw-Arrow_back:before {
  content: "\e900";
}
.bw-clock_in_blog:before {
  content: "\e903";
  color: #efefef;
}
.bw-Close:before {
  content: "\e904";
}
.Instagram_icon_in_email_design:before {
  content: "\e905";
}
.bw-lets_talk_arrow:before {
  content: "\e906";
  color: #fff;
}
.bw-linkedin:before {
  content: "\e907";
}
.bw-Our_circle_in_homepage:before {
  content: "\e90a";
}
.bw-Pdf:before {
  content: "\e90b";
}
.bw-Personal_information_drop_sown_menu_arrow:before {
  content: "\e90c";
}
.bw-resend_recover_link:before {
  content: "\e90f";
}
.bw-save_link_icon:before {
  content: "\e910";
}
.bw-Shop_errow_Button:before {
  content: "\e911";
}
.bw-twitter:before {
  content: "\e912";
}
.bw-e-mailed-your-password-reset-link-again:before {
  content: "\e913";
}
